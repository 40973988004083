.kya-container {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 100%;
    padding: 40px 60px 40px 60px;
    /* background-color: #f9f9f9; */
    background: linear-gradient(to bottom, #FFFFF2, #FFFFE8);
    font-family: Satoshi, sans-serif;
  }
  
  .kya-content {
    max-width: 1440px;
    margin: auto;
    /* text-align: center; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .kya-images {
    margin-bottom: 20px;
    width: 50%;
    height: auto;
    /* display: flex;
    justify-content: center;
    margin-bottom: 20px; */
  }
  
  .phone-image {
    width:75%;
    height: auto;
    /* margin-left: -109px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  .phone-image-about {
    width:37%;
    height: auto;
    margin-left: 50%;
    margin-top: 10%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  
.kya-text {
    font-family: Satoshi;
    width: 50%;
    text-align: justify;
    padding-right: 75px;
    padding-left: 75px;
}

.kya-text-p {
    line-height: 28px;
    font-weight: 400;
    font-size: 16px !important;
    color: #323232 !important;
}

  .kya-text h2 {
    font-size: 24px;
    margin-bottom: 32px;
  }

.getourapp {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 27px !important;
  margin-bottom: 16px !important;
  color: #5B636F !important;
}

  .kya-text p {
    font-size: 14px;
    line-height: 24px;
    color: #323232;
    margin-bottom: 18px;
    font-weight: 400;
    /* font-family: Satoshi; */
  }
  
  .app-store-link img {
    width: 132px;
    height: auto;
  }
  
  .about-underline {
    margin-top: 5%;
    margin-bottom: unset;
    border: unset;
    width: 100px;
    background: #EEEFD4 !important;
    height: 2px !important;
    border-top: unset;
  }
