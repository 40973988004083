/* VideoPlayer.css */
.video-player {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px;
  max-width: 100%;
}

.responsive-video {
  width: 70%;
  /* max-width: 1480px; Set a max-width if needed */
  height: auto;
  border: 1px solid #ccc; /* Optional border for styling */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
}
@media (max-width: 600px) {

  .responsive-video {
    width: 100%;
  }

  .video-player {
    margin: 70px 0px 0px 0px !important;
  }
}