/* .icon-display {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFFFFF;
  } */


  /* Video Icon Display css  */
  
  /* .icon-display { */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: #FFFFFF;
  } */

  /* Photo Icon Display CSS */

.icon-display-img {
  margin: auto;
  max-width: 1440px;
}

  .icon-display {
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
}
  
  .icon-display img {
    width: 10%;
    height: auto;
  }

  .icon-display video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  