.footer {
  background: linear-gradient(to bottom, #E2ECFA, #E5EEFA);
    padding: 40px 80px 40px 80px;
    text-align: center;
  }
  .footer-wrapper{
    max-width: 1440px;
    margin: auto;
  }
  .footer-container {
    /* display: flex;
    justify-content: space-around; */
    margin-bottom: 20px;
  }
  
  .footer-section {
    flex: 1;
    text-align: justify;
  }

  .footer-section p {
    font-family: Satoshi, sans-serif;
  }
  
  .footer p a {
    text-decoration: none;
    font-weight: 600;
    color: #323232;
    letter-spacing: 0.01em;
    font-size: 1.8em;
  }

  .footer-section h3 {
    font-size: 13px;
    color: #6F7783;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 23px;
  }
  
  .footer-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: none;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    color: #262626;
  }
  
  .footer-social {
    margin-right: 3.5rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .footer-close {
    color: #262626;
    cursor: pointer;
  }
  
  .footer-close:hover {
    text-decoration: none;
  }
  
  .footer-social:hover {
    text-decoration: underline;
  }
  
  .footer-bottom span {
    font-size: 1.2em;
  }
  
  .footer a {
    color: inherit;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: none;
  }
  
  .footer p {
    margin: 5px 0;
  }
  
  .footer-container a {
    display: block;
  }
  
  .footer p,
  .footer span {
    font-size: 0.9em;
    color: #323232;
  }
  
  .footer p a,
  .footer span a {
    font-size: 16px;
  }
  
  .footer span a:hover {
    text-decoration: none;
  }
 .footer-data {
    display: flex;
    /* align-items: center; */
    /* margin: 20px; */
    width: 50%;
} 

.footer-logo {
    font-size: 1.5em;
    align-items: flex-start;
    display: flex;
    /* margin-left: 50px; */
    margin-top: 10px;
    margin-bottom: 20px;
}

.footer-bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.footer-bottom a {
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.footer-copyright p {
  font-size: 16px;
  font-weight: 400;
}

.footer-copyright {
  font-family: 'Satoshi', sans-serif;
}

.footer-underline {
    width: 100%;
    background: rgba(128, 128, 128, 0.614) !important;
    height: 1px !important;
    margin-bottom: 10px;
}

.footer-h3 {
  color: #808166 !important;
  margin-bottom: 20px !important;
  margin-top: 12px !important;
}

@media (max-width: 768px) {
  .footer-bottom-container {
    flex-direction: column;
  }
  .footer-data {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
  .footer-logo {
    justify-content: flex-start;
  }
  .footer-wrapper {
    margin-left: 0px; 
    margin-right: 0px;
  }
}


@media (max-width: 1024px) {
  .footer-data {
    width: 100%;
  }
}