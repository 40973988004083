.termsofuse {
    max-width: 1150px;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 80px;
    margin: auto;
    text-align: justify;
}
h3 {
    font-weight: 500;
    font-size: 1.75rem;
}
.termsofuselist{
    padding-left: 30px;
}

.termsofuselist li {
    list-style: disc !important;
}
@media (max-width:1200px) {
    .termsofuse{
        padding-left: 30px;
        padding-right: 30px;
    }
    
}