.whoisall {
  width: 100%;
    text-align: center;
    padding: 100px 0px 0px 0px;
    font-family: Satoshi, sans-serif !important;
    background: linear-gradient(to bottom, #FFFFE8, #F8F9DE ,#E3E9EE);
  }
  .whoisall-wrapper {
    max-width: 1440px;
    margin: auto;
  }

  .whoisall h2 {
    max-width: 1440px;
    margin: 0px auto 44px auto;
    font-size: 24px;
    
    color: #333;
    font-weight: 700;
  }
  
  .content-container {
    display: flex;
    /* max-width: 1400px; */
    /* margin: auto; */
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
    /* align-items: center; */
  }

  .icon-placeholder img {
    width:56px;
    /* margin-top: -25px; 
    margin-left: -12px; */
  }
  
  .content-section {
    width: 400px;
    /* background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 20px 0px 62px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-section h2 {
    font-size: 16px !important;
    font-weight: 700;
  }
  
  .icon-placeholder {
    width: 50px;
    height: 50px;
    background-color: #e8b1b1;
    border-radius: 50%;
    margin-bottom: 24px;
  }
  
  .content-section h2 {
    margin-top: 0;
    color: #323232;
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 16px;
  }
  
  .content-section p {
    color: #323232;
    font-size: 14px;
    line-height: 1.6;
    width: 342px;
  }
  

  hr {
    margin-top: unset;
    margin-bottom: unset;
    line-height: 24px;
    border: unset;
    width: 100px;
    background: #ffffff !important;
    height: 2px !important;
    border-top: unset;
  }

  @media (max-width: 768px) {
    .kya-content {
      flex-direction: column;
    }
    .kya-images {
      width: 100%;
      padding: 0px 0px 0px 0px;
    }
    .kya-container {
      padding: 0px 0px 0px 0px;
    }
    .phone-image-about {
      margin-left: 0px;
      padding: 10px 30px 10px 30px;
      width: 100%;
    }
    .kya-text {
      width: 100%;
      padding-right: 50px;
      padding-left: 50px!important;
    }
  }

  @media (max-width: 820px) {
    .kya-content {
      flex-direction: column;
    }
    .kya-images {
      width: 100%;
      height: 100%;
    }
    .kya-text {
      padding-left: 15px;
      width: 100%;
    }
  }

  
  @media (max-width: 376px) {
    .content-section {
      width: 380px;
    }
  }
  @media (max-width: 360px) {
    .content-section {
      width: 360px;
    }
  }

  @media (max-width: 320px) {
    .content-section p {
      width: 290px;
    }
    .content-section {
      width: 335px;
    }
  }