@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
    background-color: cadetblue;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

#menu {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

#menu.visible {
  opacity: 1;
}