/* src/ContactForm.css */
/* 
body {
    font-family: Arial, sans-serif;
    background-color: #f0f4f8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
   */
  .contact-container {
    /* display: flex; */
    background: linear-gradient(to bottom, #E3E9EE,#E7EFFB);
    /* padding: 40px 60px 40px 40px; */
    /* border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 100%;
  }

  .contact-form {
    width: 50%;
    padding: 30px 50px 30px 50px;
  }
  
  .contact-form h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }
  
  .contact-form p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #323232;
  }
  .contact-wrapper{
    display: flex;
    max-width: 1440px;
    margin:auto
  }
  .contact-form .form-group {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 0px solid #ccc;
    border-radius: 8px;font-size: 18px;
  }
  .contact-form textarea {
    margin-bottom: 20px;
    height: 150px;
  }

  input::placeholder, textarea::placeholder {
    color: rgba(26, 26, 26, 1);
  }

  .contact-form input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 0;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    border: 1px solid #222222;
    background-color: transparent;
    /* appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer; */
  }
  
  .contact-form label {
    display: inline-block;
    margin-bottom: 10px;
    color: #323232;
  }
  
  .contact-form button {
    background-color: #6b8e23;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #556b2f;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px 50px 30px 0px;
    width: 50%;
  }
  
  .phone-image-contactus {
    /* max-width: 150px; */
    margin-bottom: 20px;
  }
  
  .contact-details {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 35px;
    /* margin-left: 30px; */
    /* Add above margin-left if adding Phone section */
    margin-left: 137px;
  }
  
  .contact-method {
    display: flex;
    align-items: center;
  }
  
.contact-method h4 {
  font-size: 16px !important;
  font-weight: 700;
  margin: 0;
}

.contact-method p {
  font-size: 14px !important;
  margin: 0;
}

.contact-method div {
    display: flex;
    flex-direction: column;  /* Arrange text in a column */
  }

  .contact-icon {
    margin-right: 10px;
    height: 50%;
  }
  

  .response-message {
      background-color: #F8F9DE;
      color: #6C6D52;
      padding: 10px;
      /* border-radius: 5px; */
      /* border: 1px solid #c3e6cb; */
      /* margin-top: 10px; */
      /* max-width: 300px; */
      text-align: center;
      width: 65%;
      margin: auto;
      opacity: 1;
      transition: opacity 1s ease-in-out;
  }
  
  .response-message.fade-out {
    opacity: 0;
  }


  /* src/CSS/ContactForm.css */
.contact-container {
    /* display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; */
    font-family: Satoshi, sans-serif;
  }
  
  .contact-form {
    /* background-color: #f9fafc; */
    padding: 40px;
    margin-left: 103px;
    margin-right: 50px;
    /* border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  }
  
  .contact-form h4 {
    margin: 0;
    color: #99a1a6;
    font-family: Satoshi, sans-serif;
    font-weight: 700 !important;
  }
  
  .contact-form h2 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    color: #2a2a2a;
    font-family: Satoshi, sans-serif;
  }
  
  .contact-form p {
    margin-bottom: 25px;
    color: #323232;
    font-size: 18px;
    font-family: Satoshi, sans-serif;
  }
  
  .form-group {
    display: flex;
    gap: 10px;
    margin-bottom: 2px;
  }
  
  /* .form-group input {
    flex: 1;
  } */
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 0px solid #d1d9de;
    border-radius: 7px;
    font-size: 18px;
    font-weight: 500;
    background-color: #fff;
    opacity: 40%;
    transition: opacity 0.3s ease; 
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    opacity: 100%;
    outline: none; /* Remove default focus outline */
  }

  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  label {
    font-size: 14px;
    color: #6e7a84;
  }
  
  button[type="submit"] {
    background-color: #808166;
    /* color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 65%;
    margin-left: 80px; */
    width: 65%;
    margin: 20px auto;
  }
  
  button[type="submit"]:hover {
    background-color: #9f9f8e;
  }
  
.contact-form p {
  width: 100%;
}

/* .contact-form form {
  width: 90%;
} */

:focus-visible {
  outline: none;
}
.contact-wrapper{
  display: flex;
  max-width: 1440px;
  margin:auto
}

/* .form-group {
  margin-bottom: 15px;
}

.form-field {
  display: inline-block;
  width: 48%;
  vertical-align: top;
}

.form-field + .form-field {
  margin-left: 4%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
} */

/* .form-label {
  font-size: 18px;
  font-weight: 500;
  color: #323232;
} */

.form-label {
  /* opacity: 0;
  transition: opacity 0.3s ease-in-out; */
  font-size: 18px;
  font-weight: 500;
  color: #323232;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

/* .form-div input:focus + .form-label,
.form-div input:not(:placeholder-shown) + .form-label {
  opacity: 1;
} */


@media (max-width: 1024px) {
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 1  0px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .contact-info {
    align-items: center;
  }
}


@media (max-width: 912px) {
  .contact-form {
    padding-left: 35px;
    padding: 0px;
    margin-left: 35px;
    margin-right: 0px;
    width: 60%;
  }
}

@media (max-width: 768px) {
  .contact-info {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .contact-details {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 35px;
    /* margin-left: 30px; */
    padding: 0px 0px 0px 30px;
}
.contact-form {
  width: 100%;
  padding: 30px 40px 30px 60px;
  margin-left: 0px;
  margin-right: 0px;
}
.contact-wrapper {
  flex-direction: column;
}
button[type="submit"] {
  width: 60%;
  margin-left: 60px;
}
}

@media (max-width: 430px) {
  .contact-details {
    padding: 0px 0px 0px 0px;
    gap: 20px;
  }
}

@media (max-width: 425px) {
  .contact-details {
    flex-direction: column;
  }
}

@media (max-width: 376px) {
  .contact-form {
    padding: 10px 5px 10px 25px;
  }
}

@media (max-width: 320px) {
  .contact-info {
    padding: 0px 0px 0px 0px;
    align-items: center;
    justify-content: center;
  }
}

.form-label {
  opacity: 0;
  position: absolute;
  top: -20px;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.form-div {
  position: relative;
}

.form-label.visible {
  opacity: 1;
  top: 0;
}

input, textarea {
  margin-top: 20px;
}

input, textarea, .form-div {
  width: 100%;
}

.form-div {
  position: relative;
  margin-bottom: 20px;
}

.form-label {
  opacity: 0;
  position: absolute;
  top: -20px;
  left: 0;
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
  pointer-events: none;
}

.form-label.visible {
  opacity: 1;
  top: -20px;
}


input, textarea {
  margin-top: 20px;
}

input, textarea, .form-div {
  width: 100%;
}

.contact-email a {
  text-decoration: none !important;
  color: #323232;
  font-weight: 500;
}

.contact-email a:hover {
  font-weight: 600;
}